header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    max-width: 1920px;
    margin: 0 auto;
    z-index: 171; }

.homepage {
    height: 90vh;
    @media (max-width: #{map-get($grid-breakpoints, md)}) {
        height: 70vh; } }

.subpage {
    height: 69vh;
    display: block;
    width: 100%;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    @media (max-width: #{map-get($grid-breakpoints, md)}) {
        height: 60vh; } }

.hero-image-section {
    width: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    picture {
        width: 100%;
        height: 100%;
        object-fit: cover;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover; } }

    h2 {
        font-weight: 400;
        color: white;
        position: absolute;
        bottom: 43%;

        @media (min-width: #{map-get($grid-breakpoints, xl)}) {
            font-size: 80px;
            bottom: 38%; }

        @media (min-width: #{map-get($grid-breakpoints, xxl)}) {
            left: 30%;
            font-size: 100px; } }

    .hero-image-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(0deg, rgba(0,0,0,1) 3%, rgba(255,255,255,0) 70%); }

    .logo-container {
        background-color: $color-2;
        width: 215px;
        height: 160px;
        position: fixed;
        top: 0;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
        z-index: 200;
        transition: 0.5s;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            padding: 30px;

            @media (max-width: #{map-get($grid-breakpoints, sm)}) {
                padding: 17px; } }

        @media (max-width: #{map-get($grid-breakpoints, lg)}) {
            width: 200px;
            height: 150px; }
        @media (max-width: #{map-get($grid-breakpoints, md)}) {
            left: 0;
            width: 150px;
            height: 125px;
            position: absolute; }

        @media (max-width: #{map-get($grid-breakpoints, sm)}) {
            width: 120px;
            height: 94px; } }

    .logo-container-smaller {
        width: 180px;
        height: 120px;
        transition: 0.5s;
        box-shadow: 0px 6px 11px 4px rgba(0,0,0,0.22);
        @media (max-width: #{map-get($grid-breakpoints, lg)}) {
            width: 170px;
            height: 130px; }
        @media (max-width: #{map-get($grid-breakpoints, md)}) {
            width: 150px;
            height: 125px; } }

    .header-deko-container {
        position: absolute;
        bottom: -8%;
        left: -7%;
        width: 43%;

        @media (max-width: #{map-get($grid-breakpoints, xxl)}) {
            width: 54%; }

        @media (max-width: #{map-get($grid-breakpoints, lg)}) {
            width: 70%; }

        @media (max-width: #{map-get($grid-breakpoints, sm)}) {
            width: 100%; }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover; } } }

.subpage-form {
    position: absolute;
    bottom: 0; }
