$text-content-color-1: #0091A7;
$text-content-color-2: #000;

.block-text-content {
  padding: 40px 170px;
  @media (max-width: #{map-get($grid-breakpoints, lg)}) {
      padding: 35px 40px; }

  a {
    display: inline-flex;
    align-items: center;
    font-size: 1.8rem;
    color: $text-content-color-2;
    text-decoration: none;

    strong {
      flex: 1 0 auto;
      position: relative;
      display: inline-block;

      &:before {
        width: 0;
        bottom: -1px;
        height: 3px;
        content: "";
        position: absolute;
        background-color: $text-content-color-2;
        transition: 0.3s; }

      &:hover:before {
        width: 100%;
        transition: 0.3s; } } }

  &:hover {
    text-decoration: none;

    .arrow-link {
      margin-left: 2.5*$base-width; } } }
