* {
    padding: 0;
    margin: 0;
    box-sizing: border-box; }

html {
    font-size: 10px;
    overflow-x: hidden;
    overflow-y: scroll; }

body {
    -webkit-text-size-adjust: none;
    font-size: 1.6rem;
    color: $body-color;
    line-height: 160%;
    font-weight: 300;
    font-family: $font-default;
    overflow-x: hidden;
    max-width: 1920px;
    margin: 0 auto;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);

    @media only screen and (min-width: #{map-get($breakpoints, s)}) {
        font-size: 2rem; }

    &.customize-support {
 } }        // if logged in

img {
    display: block;
    max-width: 100%; }

main {
    position: relative; }

.block {
    position: relative;
    width: 100%;
    margin: 0;
    //padding: 0 2 * $base-width

    // @media only screen and (min-width: #{map-get($breakpoints, s)})
    //     padding: 0 4 * $base-width

    // .block
 }    //     padding: 0

.body_overlay {
    &::after {
        content: '';
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9; } }

//fancybox moves left and right side inwards
.compensate-for-scrollbar {
    margin-right: 0!important;
    margin-left: 0!important; }

.arrow-link {
    margin-left: 10px;
    transition: 0.5s;
    img {
        width: 20px;
        height: 20px;
        display: inline; } }

// filter
.filter-white {
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(12deg) brightness(114%) contrast(106%); }

.global-padding {
    padding: 0 170px;
    @media (max-width: #{map-get($grid-breakpoints, xl)}) {
        padding: 0 120px; }
    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        padding: 0 60px; }
    @media (max-width: #{map-get($grid-breakpoints, md)}) {
        padding: 0 30px; } }

.global-max-width {
    max-width: 2560px; }
