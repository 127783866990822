/*Impression Slider Varibales*/
$impressions-slider-color-1: #0091A7;
$impressions-slider-color-2: rgba(0, 145, 167, 0.77);
$impressions-slider-color-3: #357E81;
$impressions-slider-color-4: #e8f0f1;

.block-impressions-slider {
  border-top: 3px solid $impressions-slider-color-4;
  background-color: $impressions-slider-color-4;
  position: relative;

  .xl-space {
    padding-top: 175px;
    padding-bottom: 375px;
    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
      padding-top: 50px;
      padding-bottom: 50px; } }
  .lg-space {
    padding-top: 175px;
    padding-bottom: 175px;
    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
      padding-top: 50px;
      padding-bottom: 50px; } }
  .md-space {
    padding-top: 125px;
    padding-bottom: 125px;
    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
      padding-top: 50px;
      padding-bottom: 50px; } }

  .impressions-slider-container {
    position: relative;

    h2 {
      color: $impressions-slider-color-1;
      text-align: center;
      margin: 0;
      padding-bottom: 40px; }

    p {
      text-align: center;
      font-size: 18px;
      margin-bottom: 15px; }

    .impressions-slider-inner {
      padding: 50px 170px 0 170px;
      @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        padding: 60px 40px; }

      .owl-stage-outer {
        margin: auto;
        z-index: 1;
        .impressions-slider-item {
          display: flex;
          height: 516px;
          width: 100%;
          .impressions-slider-image {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            img, picture {
              width: 100%;
              height: 100%;
              object-fit: cover;
              &::after {
                content: url('/wp-content/uploads/hover-cross.svg');
                width: 100%;
                height: 100%;
                background-color: $impressions-slider-color-2;
                opacity: 0;
                transition: 0.5s;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 0;
                left: 0; }
              &:hover {
                &::after {
                  opacity: 1;
                  transition: 0.5s; } } } }
          @media (max-width: #{map-get($grid-breakpoints, lg)}) {
            height: 450px; }
          @media (max-width: #{map-get($grid-breakpoints, md)}) {
            height: 350px;
            justify-content: center;
            a {
              width: 70%; } } } }


      .owl-nav {
        justify-content: space-between !important;
        display: flex !important;
        position: absolute;
        left: 50px;
        right: 50px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 0;

        @media (max-width: #{map-get($grid-breakpoints, lg)}) {
          top: 100%;
          height: 50px;
          justify-content: center!important; }

        button {
          border: 3px solid $impressions-slider-color-1;
          width: 80px;
          height: 80px;
          display: flex;
          align-items: center;
          transition: 0.5s;
          background-color: transparent;
          border-radius: 50%;
          box-shadow: 0px 0px 7px rgba( 0, 0, 0, 0.33);
          @media (max-width: #{map-get($grid-breakpoints, lg)}) {
            width: 40px;
            height: 40px;
            margin: 0 10px; }

          .owl-carousel-arrow {
            background-color: transparent;
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            img {
              width: 100%;
              height: auto;
              padding: 35%;
              filter: invert(37%) sepia(93%) saturate(3672%) hue-rotate(166deg) brightness(96%) contrast(101%);
              transition: 0.5s;
              background-color: transparent;
              border-radius: 50%; } }

          &:hover {
            background-color: $impressions-slider-color-1;
            transition: 0.5s;
            .owl-carousel-arrow {
              img {
                filter: invert(100%) sepia(96%) saturate(21%) hue-rotate(48deg) brightness(105%) contrast(101%);
                transition: 0.5s; } } } } } } } }


.slider-curve-end {
  position: relative;
  height: 130px;
  background-color: $impressions-slider-color-4;
  clip-path: ellipse(45% 100% at 50% 100%);
  transform: rotate(180deg);
  z-index: 2;
  width: 160%;
  margin-left: -30%;
  margin-right: -30%;
  margin-bottom: -5%;
  @media (max-width: #{map-get($grid-breakpoints, lg)}) {
    margin-bottom: -10%; } }

.block-impressions-slider-filter-forest {
  background-color: $impressions-slider-color-3;

  .forest-siluette-container-slider {
    width: 50%;
    position: absolute;
    z-index: 3;
    right: 0;
    margin-top: -25%;
    margin-right: -5%;
    transform: rotateY(180deg);
    img {
      object-fit: cover;
      width: 100%;
      height: 100%; } }

  .impressions-slider-container {

    h2, p {
      color: $impressions-slider-color-4; }

    p {
      line-height: 30px; } }

  .owl-nav {
    button {
      border: 3px solid #fff!important;
      .owl-carousel-arrow {
        img {
          filter: invert(100%) sepia(96%) saturate(21%) hue-rotate(48deg) brightness(105%) contrast(101%)!important;
          transition: 0.5s; } }
      &:hover {
        background-color: #fff;
        transition: 0.5s;
        .owl-carousel-arrow {
          img {
            filter: invert(100%) sepia(96%) saturate(21%) hue-rotate(48deg) brightness(105%) contrast(101%)!important;
            transition: 0.5s; } } } } } }
