#footer_wrap {
    background-image: url('/wp-content/uploads/footer_background.jpg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    padding-top: 115px;
    padding-bottom: 115px;
    color: white;
    position: relative;
    height: auto;
    min-height: 500px;
    overflow: hidden;

    h6 {
        font-size: 20px;
        margin-bottom: 25px; }

    p,li,a {
        font-size: 15px; }

    a {
        color: #fff;
        text-decoration: none;
        &:hover {
            color: #fff;
            text-decoration: underline; } }

    .footer-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.88); }

    .footer-saw {
        position: absolute;
        right: -10%;
        top: 60px;
        pointer-events: none;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            @media (max-width: #{map-get($grid-breakpoints, lg)}) {
                opacity: 0.2; } } }

    .footer-wood {
        position: absolute;
        bottom: -82px;
        height: 164px;
        width: 110%;
        margin: 0 -5%;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain; } }

    .footer-icon-container {
        display: flex;
        justify-content: start;
        align-items: center;
        font-size: 15px;
        margin-bottom: 15px;

        .footer-icon {
            margin-right: 11px; } }

    #menu-footer-menu {
        list-style: none; }

    .footer-boxes-row {
        display: flex;
        flex-direction: row;
        position: relative;
        padding: 0 20px;
        flex-wrap: wrap;
        #col-1 {
            width: 25%;
            @media (max-width: #{map-get($grid-breakpoints, xl)}) {
                width: 40%;
                padding: 5%; }
            @media (max-width: #{map-get($grid-breakpoints, lg)}) {
                width: 90%;
                padding: 5%; } }

        #col-2 {
            width: 40%;
            padding: 0 10% 0 0;
            @media (max-width: #{map-get($grid-breakpoints, xl)}) {
                width: 40%;
                padding: 5%; }
            @media (max-width: #{map-get($grid-breakpoints, lg)}) {
                width: 90%;
                padding: 5%; } }

        #col-3 {
            width: 30%;
            @media (max-width: #{map-get($grid-breakpoints, xl)}) {
                width: 90%;
                padding: 5%; } } } }
