/* Third-Party */
@import '~bootstrap/scss/bootstrap';
@import '~owl.carousel/dist/assets/owl.carousel';
@import '~@fancyapps/fancybox/dist/jquery.fancybox.min';


/* Globals */
@import './globals/vars';
// @import './globals/fonts'
@import './globals/base';
// @import './globals/bem-grid-beta'

/* Layout-Parts */
@import './layout/typo';
@import './layout/header';
@import './layout/footer';
@import './layout/navigation';
@import './layout/sidebar';
@import './layout/buttons';
@import './layout/hamburger';
@import './layout/fancybox';

/* Blocks */
@import '../../blocks/headline/style';
@import '../../blocks/container/style';
@import '../../blocks/picture-menu/style';
@import '../../blocks/text-medien/style';
@import '../../blocks/impressions-slider/style';
@import '../../blocks/cards-slider/style';
@import '../../blocks/project-steps/style';
@import '../../blocks/banner/style';
@import '../../blocks/wave-text-block/style';
@import '../../blocks/gallery-sort/style';
@import '../../blocks/team/style';
@import '../../blocks/quote/style';
@import '../../blocks/text-content/style';
@import '../../blocks/articles/style';
@import '../../blocks/contact-form/style';
