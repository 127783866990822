
$form-color-1: #1D1D1B;

.block-contact-form {
    padding: 40px 130px;
    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        padding: 35px 40px; }

    input,
    select,
    textarea {
        padding: 0 15px;
        height: 45px;
        font-size: 16px; }

    textarea {
        height: 150px;
        padding: 15px; }

    .contact-form-form-container,
    .contact-form-map-container,
    .contact-form-contact-container {
        padding: 25px 40px;
        @media (max-width: #{map-get($grid-breakpoints, lg)}) {
            padding: 20px 0; } }

    .contact-form-map-container {
        width: 100%;
        border: 5px solid $color-2;
        border-radius: 25px;
        padding: 0;
        overflow: hidden;
        height: 80vh;
        @media (max-width: #{map-get($grid-breakpoints, lg)}) {
            height: 50vh; }
        iframe {
            height: 100%;
            width: 100%; } }

    .contact-form-contact-container {
        .contact-form-adress-container,
        .contact-form-info-container,
        .contact-form-social-container {
            margin-bottom: 25px; }

        h3 {
            margin-bottom: 25px; } }

    .contact-form-icon-item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .contact-form-info-icon {
            display: flex;
            align-items: center;
            width: 20px;
            height: 20px;
            margin-right: 10px;
            img {
                width: 20px;
                height: 20px; } }
        a {
            color: $form-color-1;
            text-decoration: none;
            &:hover {
                color: $form-color-1;
                text-decoration: underline; } } }

    // .contact-form-section
    //     margin-bottom: 5px

    .form-check-inline {
        .form-check-label {
            margin-top: 0;
            padding: 0 20px; } }

    input.invalid {
        background-color: #ffdddd; }

    label {
        font-size: 19px;
        margin-top: 20px;
        color: grey;
        width: 100%; }

    .form-error {
        border: 2px solid rgba(208, 0, 0, 0.8);
        padding: 25px;
        background-color: rgba(208, 0, 0, 0.25); }

    .form-success {
        border: 2px solid rgba(46, 213, 138, 0.8);
        padding: 25px;
        background-color: rgba(46, 213, 138, 0.25); }

    .error {
        border: 2px solid rgba(208, 0, 0, 0.8);
        background-color: rgba(208, 0, 0, 0.25); } }

input[type="text"]#verify {
    transform: translateX(-9999px); }
