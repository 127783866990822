/*Card Slider Variables*/
$slider-cards-color-1: #0091A7;
$slider-cards-color-2: #8EB2BA;
$slider-cards-color-3: #000;
$slider-cards-color-4: #fff;
$slider-cards-item-hover-color-1: rgba(0, 145, 167, 0.77);
$slider-cards-item-hover-color-2: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.88) 100%);

$slider-cards-base-width: .8rem;

@keyframes fadeInText {
  0% {
    opacity: 0; }
  70% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes widthGrow {
  0% {
    width: 30%; }
  60% {
    width: 30%; }
  100% {
    width: 40%; } }

.block-cards-slider {
  padding: 2*$slider-cards-base-width 0 13*$slider-cards-base-width;

  .cards-slider-container {
    position: relative;
    width: 66%;

    @media only screen and (max-width: #{map-get($grid-breakpoints, lg)}) {
      width: 86%; }

    @media only screen and (max-width: #{map-get($grid-breakpoints, md)}) {
      width: 100%; } }

  .owl-stage-outer {
    @media only screen and (min-width: #{map-get($grid-breakpoints, md)}) {
      overflow: visible;

      .owl-stage {
        display: flex;
        align-items: stretch;

        .owl-item {
          display: flex;
          flex-direction: column; } } } }

  .owl-dots {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    align-content: flex-end;

    @media only screen and (min-width: #{map-get($grid-breakpoints, md)}) {
      position: absolute;
      left: 48%;
      bottom: 0; }

    .owl-dot {
      background-color: $slider-cards-color-2;
      width: 5*$slider-cards-base-width;
      height: 0.5*$slider-cards-base-width;
      margin-right: 1.25*$slider-cards-base-width;

      &.active {
        height: 1.25*$slider-cards-base-width;
        background-color: $slider-cards-color-1; } } }

  .owl-nav {
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 50px;
    right: 50px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 0;

    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
      top: 100%;
      height: 40px;
      justify-content: center!important; }

    button.owl-prev, button.owl-next {
      border: 3px solid $impressions-slider-color-1;
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      transition: 0.5s;
      background-color: transparent;
      border-radius: 50%;
      box-shadow: 0px 0px 7px rgba( 0, 0, 0, 0.33);
      @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        width: 40px;
        height: 40px;
        margin: 0 10px; }

      .owl-carousel-pfeil {
        background-color: transparent;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        img {
          width: 100%;
          height: auto;
          padding: 35%;
          filter: invert(37%) sepia(93%) saturate(3672%) hue-rotate(166deg) brightness(96%) contrast(101%);
          transition: 0.5s;
          background-color: transparent;
          border-radius: 50%; } }

      &:hover {
        background-color: $slider-cards-color-1;
        transition: 0.5s;
        .owl-carousel-pfeil {
          img {
            filter: invert(100%) sepia(96%) saturate(21%) hue-rotate(48deg) brightness(105%) contrast(101%);
            transition: 0.5s; } } } } }

  .card-slider-card-item {
    display: flex;
    height: 100%;
    position: relative;
    z-index: 0;

    @media only screen and (max-width: #{map-get($grid-breakpoints, md)}) {
      flex-direction: column; }

    .card-slider-card-item-image {
      display: flex;
      align-items: stretch;
      width: 30%;
      height: 100%;
      min-height: 650px;
      z-index: 1;
      overflow: hidden;

      @media only screen and (max-width: #{map-get($grid-breakpoints, md)}) {
        width: 100%;
        height: auto;
        min-height: unset; }

      .img-wrapper {
        flex: 0 0 100%;
        position: relative;
        padding-bottom: 169%;

        @media only screen and (max-width: #{map-get($grid-breakpoints, md)}) {
          padding-bottom: 57%; }

        &:after {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: $slider-cards-item-hover-color-1;
          opacity: 0;
          z-index: 10;
          transition: all .3s ease-in-out; }

        &:hover {
          img {
            transform: scale(1.25); }

          &:after {
            opacity: 1; } }

        img {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          transition: all .5s ease-in-out; }

        .card-slider-card-item-image-overlay {
          background: $slider-cards-item-hover-color-2;
          position: absolute;
          height: 50%;
          width: 100%;
          bottom: 0;
          left: 0;
          z-index: 20;
          transition: all .5s ease-in-out; }

        h3 {
          position: absolute;
          right: $slider-cards-base-width;
          bottom: $slider-cards-base-width;
          left: $slider-cards-base-width;
          text-align: center;
          width: calc(100% - 2 * #{$slider-cards-base-width});
          font-size: 2.8rem;
          font-size: calc(18px + (28 - 18) * (100vw - 992px) / (2560 - 992));
          color: $slider-cards-color-4;
          z-index: 30;
          transition: all .3s ease-in-out; } } }

    .card-slider-card-item-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      width: 60%;
      padding: 6*$slider-cards-base-width 8%;
      z-index: -1;
      opacity: 0;

      @media only screen and (max-width: #{map-get($grid-breakpoints, md)}) {
        width: 100%; }

      h2 {
        color: $slider-cards-color-1;
        text-align: left;
        margin-bottom: 3*$slider-cards-base-width;
        font-size: calc(40px + (60 - 40) * (100vw - 992px) / (2560 - 992)); }

      p {
        text-align: left;
        line-height: 1.5;
        margin-bottom: 2*$slider-cards-base-width;
        color: $slider-cards-color-3; }

      .card-slider-card-item-link-container {
        margin-top: 2*$slider-cards-base-width;

        a {
          display: flex;
          align-items: center;
          font-size: 1.8rem;
          color: $slider-cards-color-3;
          text-decoration: none;

          strong {
            flex: 1 0 auto;
            position: relative;
            display: inline-block;

            &:before {
              width: 0;
              bottom: -1px;
              height: 3px;
              content: "";
              position: absolute;
              background-color: $slider-cards-color-3;
              transition: 0.3s; }

            &:hover:before {
              width: 100%;
              transition: 0.3s; } } }

        &:hover {
          text-decoration: none;

          .arrow-link {
            margin-left: 2.5*$slider-cards-base-width; } } } } }

  .owl-item {
    &:not(.active) {
      cursor: pointer; }

    &.active {
      + .owl-item ~ .owl-item {
        @media only screen and  (min-width: #{map-get($grid-breakpoints, lg)}) {
          transform: translateX(-70%); } }

      .card-slider-card-item {
        .card-slider-card-item-image {
          @media only screen and (min-width: #{map-get($grid-breakpoints, md)}) {
            width: 40%;
            animation: 1.5s widthGrow ease-in-out;
            animation-delay: 0s; }

          .img-wrapper {
            @media only screen and (min-width: #{map-get($grid-breakpoints, md)}) {
              padding-bottom: 126%; }

            &:after {
              opacity: 0; }

            img {
              transform: scale(1); } }

          h3 {
            opacity: 0; } }

        .card-slider-card-item-text {
          animation: 2s fadeInText ease-in-out;
          animation-delay: 0s;
          opacity: 1;

          @media only screen and (max-width: #{map-get($grid-breakpoints, md)}) {
            animation: 1s fadeInText ease-in-out; } } } } } }
