/*Picture Menu Variables*/
$picture-menu-overlay-1: linear-gradient(0deg, rgba(0,0,0,1) 10%, rgba(255,255,255,0) 100%);
$picture-menu-overlay-2: rgba(0, 145, 167, 0.5);
$picture-menu-color-1: #707070;

.block-picture-menu {
  border-top: 3px solid white;
  padding: 0;
  .picture-menu-container {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
      flex-wrap: wrap; }
    .picture-menu-box {
      position: relative;
      width: 100%;
      display: flex;
      overflow: hidden;
      @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        flex: 0 0 50%;
        height: 60vh; }
      @media (max-width: #{map-get($grid-breakpoints, md)}) {
        flex: 0 0 100%; }
      &:hover {
        .picture-menu-overlay-2 {
          opacity: 1;
          transition: 0.5s; }
        .picture-menu-image-container {
          img {
            transform: scale(1.1);
            transition: 0.5s; } } }
      a {
        width: 100%; }
      picture {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.5s; }

      .picture-menu-heading {
        position: absolute;
        bottom: 10%;
        z-index: 3;
        width: 100%;

        h2 {
          color: $picture-menu-color-1;
          text-align: center; } }

      .picture-menu-overlay-1 {
        z-index: 2;
        opacity: 1;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: $picture-menu-overlay-1; }

      .picture-menu-overlay-2 {
        z-index: 1;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: $picture-menu-overlay-2;
        opacity: 0;
        transition: 0.5s; }

      .picture-menu-image-container {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: 0.5s; }
        a {
          text-decoration: none;
          display: flex;
          height: 80vh;
          width: 100%;
          justify-content: center;
          &:hover {
            text-decoration: none; } } } }


    .picture-menu-box {
      &:nth-child(odd) {
        border-right: 3px solid white;
        @media (max-width: #{map-get($grid-breakpoints, md)}) {
          border-right: none; } } } }

  .wave-svg-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 102%;
    height: auto;
    z-index: 2;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover; } } }
