$block-wave-text-block-color-1: #fff;
$block-wave-text-block-color-2: #000;
$block-wave-text-block-color-3: #0091A7;
$block-wave-text-block-bg-color-1: rgba(0, 0, 0,0.88);
$block-wave-text-block-bg-color-2: #0091A7;

.wave-text-block_svg-container {
  margin-top: -14%;
  margin-bottom: -5%;
  position: relative;
  margin-left: -2%;
  margin-right: -2%; }

.block-wave-text-block {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  border-top: 2px solid $block-wave-text-block-color-1;
  padding: 145px 170px 100px 170px;


  .wave-text-block-bgImage {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%; }

  .wave-text-block-overlay {
    width: 100%;
    height: 100%;
    background-color: $block-wave-text-block-color-2;
    top: 0;
    left: 0;
    position: absolute; }

  .black-wave {
    h2,p, a {
      color: $block-wave-text-block-color-1; }

    .wave-text-block-link-container {
      a {
        strong {
          &::before {
            background-color: #fff; } } } } }


  .white-wave {
    h2 {
      color: $block-wave-text-block-color-3; }
    p, a {
      color: $block-wave-text-block-color-2; }

    .wave-text-block-link-container {
      a {
        strong {
          &::before {
            background-color: #000; } } } } }


  .wave-text-block-inner {
    a {
      text-decoration: none;
      font-size: 18px;
      font-family: 'FilsonProBold';
      margin-top: 50px; }


    .wave-text-block-link-container {
      margin-top: 50px;
      a {
        strong {
          position: relative;
          &::before {
            width: 0%;
            bottom: -2px;
            transition: 300ms;
            height: 3px;
            content: "";
            position: absolute; }
          &:hover::before {
            width: 100%; } } }

      &:hover {
        text-decoration: none;
        .arrow-link {
          margin-left: 20px;
          transition: 0.5s; } } } }

  .white-wave {

    .cut-out-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        flex-direction: row;
        margin-bottom: -50px; }
      @media (max-width: #{map-get($grid-breakpoints, md)}) {
        flex-direction: column; }
      .text-cut-out-info-box-media {
        margin-top: -13%;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: #{map-get($grid-breakpoints, lg)}) {
            margin-top: 0; }
        img {
          max-width: 563px;
          @media (max-width: 1500px) {
            max-width: 450px; }
          @media (max-width: #{map-get($grid-breakpoints, xl)}) {
            max-width: 380px; }
          @media (max-width: #{map-get($grid-breakpoints, md)}) {
            max-width: 350px; } } }

      .text-cut-out-info-box-text {
        background-color: $block-wave-text-block-bg-color-2;
        padding: 50px;
        width: 85%;
        p, a {
          color: $block-wave-text-block-color-1;
          font-size: 18px;
          line-height: 20px;
          text-decoration: none;
          @media (max-width: #{map-get($grid-breakpoints, md)}) {
            font-size: 14px; } }
        a {
          margin-top: 0; }
        .text-cut-out-info-box-text-partner {
          margin-bottom: 25px; }
        .text-cut-out-info-box-text-kontakt {
          display: flex;
          flex-direction: column;
          .text-cut-out-info-box-text-kontakt-item {
            display: flex;
            flex-direction: row;
            justify-content: start;
            align-items: center;
            margin-bottom: 10px;
            @media (max-width: #{map-get($grid-breakpoints, md)}) {
              flex-direction: column; }
            img {
              width: 25px;
              heiht: 25px;
              margin-right: 10px;
              @media (max-width: #{map-get($grid-breakpoints, md)}) {
                margin-right: 0;
                margin-bottom: 10px; } } } } } } } }

.wave-text-block_wave-padding {
  border-top: 0 solid transparent;
  padding-top: 0;
  @media (max-width: #{map-get($grid-breakpoints, lg)}) {
    padding: 50px 40px; } }
