.block-container {
    margin: 0 auto;

    // Background
    &[class*="--bg"] {
        padding-left: 6 * $base-width;
        padding-right: 6 * $base-width; }

    &--bg {
        &-grey {
            background: grey; } }

    // Width
    &--width {
        &-boxed {
            max-width: $boxed; }

        &-small {
            max-width: $small; } }

    // Margin
    &--mt {
        &-none {
            margin-top: 0; }

        &-small {
            margin-top: 3 * $base-width; }

        &-medium {
            margin-top: 6 * $base-width; }

        &-large {
            margin-top: 10 * $base-width; } }

    &--mb {
        &-none {
            margin-bottom: 0; }

        &-small {
            margin-bottom: 3 * $base-width; }

        &-medium {
            margin-bottom: 6 * $base-width; }

        &-large {
            margin-bottom: 10 * $base-width; } }

    // Padding
    &--pt {
        &-none {
            padding-top: 0; }

        &-small {
            padding-top: 3 * $base-width; }

        &-medium {
            padding-top: 6 * $base-width; }

        &-large {
            padding-top: 10 * $base-width; } }

    &--pb {
        &-none {
            padding-bottom: 0; }

        &-small {
            padding-bottom: 3 * $base-width; }

        &-medium {
            padding-bottom: 6 * $base-width; }

        &-large {
            padding-bottom: 10 * $base-width; } } }
