.sidebar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 2%;
  right: -135px;
  flex-direction: row;
  z-index: 9999;
  width: 125px;
  transition: 0.5s;

  .sidebar_hidden-section {
    padding: 15px 0;
    height: 100px;
    background-color: $color-2;
    border: 2px solid #fff;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    border-right: 1px solid #fff;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
    margin-right: -250px;
    .sidebar_kontakt {
      display: flex;
      justify-content: start;
      align-items: center;
      margin-left: 15px;
      margin-right: 15px; }

    .sidebar-icon {
      margin-right: 15px;
      width: 20px;
      height: 20px;
      img {
        width: 20px;
        height: 20px; } }
    a {
      font-family: 'filson-pro', sans-serif;
      font-weight: 300;
      font-size: 15px;
      color: #fff;
      text-decoration: none;
      line-height: 33px;
      &:hover {
        text-decoration: underline; } } }

  .sidebar_visible-section {
    padding: 10px 0;
    height: 100px;
    background-color: $color-2;
    border: 2px solid #fff;
    border-radius: 25px;
    margin-right: 285px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
    margin-right: 233px;
    transition: 0.5s;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    &:hover {
      cursor: pointer; }
    .sidebar_text {
      font-size: 16px;
      color: #fff;
      width: 125px;
      text-align: center;
      font-family: 'filson-pro', sans-serif;
      font-weight: 500; } } }


.active-sidebar {
  right: 120px!important;

  .active-sidebar_hidden-section {
    opacity: 1;
    background-color: $color-2;
    border-right: 0px solid transparent;
    transition: 0.5s;
    pointer-events: all;
    margin-right: 0; }


  .active-sidebar_visible-section {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    border-left: 0px solid transparent;
    transition: 0.5s;
    margin-right: -5px; } }
