$team-background-color-1: #0091A7;
$team-background-color-2: #357E81;
$team-background-color-3: #fff;
$team-color-1: #fff;
$team-color-2: #0091A7;
$team-color-3: #000;
$team-filter-1: invert(100%) sepia(0%) saturate(3247%) hue-rotate(325deg) brightness(108%) contrast(111%);


.block-team {
    padding: 40px 170px;
    @media (max-width: #{map-get($grid-breakpoints, xl)}) {
        padding: 35px 100px; }
    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        padding: 35px 40px; }

    .team-profil-container {
        margin-top: 6rem;
        margin-bottom: 3rem;
        display: flex;
        flex-direction: column; }

    .team-profil-container-row {
        @media (max-width: #{map-get($grid-breakpoints, lg)}) {
            justify-content: center;
            flex-direction: row; } }


    .team-image {
        padding: 0 50px 0 50px;
        @media (max-width: #{map-get($grid-breakpoints, lg)}) {
            padding: 0 10px 0 10px;
            width: 50%; }
        @media (max-width: #{map-get($grid-breakpoints, md)}) {
            width: 100%;
            margin: 0; }

        img {
            object-fit: cover;
            width: 100%;
            height: 100%; } }

    .team-contact-info-container {
        text-align: left;
        display: flex;
        width: 100%;
        padding: 0 25px 0 25px;
        @media (max-width: #{map-get($grid-breakpoints, lg)}) {
            padding: 0 10px 0 10px;
            width: 60%;
            align-items: center;
            margin-left: -10%; }
        @media (max-width: #{map-get($grid-breakpoints, md)}) {
            width: 100%;
            margin: 0; } }


    .team-contact-info-inner {
        background-color: $team-background-color-2;
        padding: 25px;
        width: 100%;
        border-radius: 20px;
        @media (max-width: #{map-get($grid-breakpoints, lg)}) {
            height: max-content; }

        h3 {
            font-size: 28px; }

        h5 {
            font-size: 16px; }

        h3,
        h5 {
            color: $team-color-1; }

        a,p {
            font-size: 16px;
            color: $team-color-1;
            text-decoration: none;
            margin: 0;
            padding: 0; }

        a {
            &:hover {
                text-decoration: underline;
                color: $team-color-1;
                transition: $transition; } }

        .team-contact-info-profile-position {
            height: 35px; }

        .team-contact-info-description-container {
            margin-top: 15px;
            height: 150px;
            @media (max-width: #{map-get($grid-breakpoints, md)}) {
                height: auto; }
            p {
                font-size: 14px;
                margin: 0;
                padding: 0;
                line-height: 1.6; } } }

    .team-content-wrapper {
        margin: 25px 0;
        .team-contact-info-item {
            display: flex;
            margin: 5px 0;
            align-items: center;
            .team-icon {
                width: 20px;
                height: 20px;
                margin-right: 15px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    filter: $team-filter-1; } } } } }
