a {
    color: $link-color;
    text-decoration: underline;
    transition: $transition;
    -webkit-appearance: none;

    &:hover {
        color: $link-color-hover; }

    &:focus {
        outline: 0; }

    &.button {
        display: inline-block;
        text-decoration: none;
        border-radius: 10px;
        padding: 1.5 * $base-width 3 * $base-width;
        color: $button-color;
        transition: $transition;
        background: $button-bg;

        &:hover {
            background: $button-bg-hover; } } }

strong,
.bold {
    font-weight: 700; }

p {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 15px; }



.block {
    ul, ol {
        margin-left: 20px; }
    li {
        font-size: 18px; } }


button {
    &:focus {
        outline: none; } }

h1,
.h1 {
    font-family: $font-default;
    color: $color-2;
    font-size: 70px;
    line-height: 125%;
    display: block;
    font-weight: 400;
    // font-weight: 700
    margin: 80px 0;
    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        font-size: 60px;
        margin: 70px 0; }

    @media (max-width: #{map-get($grid-breakpoints, md)}) {
        font-size: 40px;
        margin: 50px 0 5px 0; }

    @media (max-width: #{map-get($grid-breakpoints, sm)}) {
        font-size: 35px;
        margin: 40px 0 5px 0; } }

h2,
.h2 {
    font-family: $font-default;
    color: $color-2;
    font-size: 60px;
    line-height: 125%;
    display: block;
    font-weight: 400;
    // font-weight: 600
    margin-bottom: 30px;

    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        font-size: 45px; }

    @media (max-width: #{map-get($grid-breakpoints, md)}) {
        font-size: 35px; }

    @media (max-width: #{map-get($grid-breakpoints, sm)}) {
        font-size: 30px; } }

h3,
.h3 {
    font-family: $font-default;
    font-size: 40px;
    line-height: 125%;
    display: block;
    font-weight: 600;

    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        font-size: 30px; }

    @media (max-width: #{map-get($grid-breakpoints, md)}) {
        font-size: 25px; } }

h4,
.h4 {
    font-family: $font-default;
    font-size: 30px;
    line-height: 125%;
    display: block;
    font-weight: 600;

    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        font-size: 20px; } }
