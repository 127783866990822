.block-headline {
    padding: 40px 170px;
    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        padding: 20px 40px; }
    &--align-left {
        display: flex;
        justify-content: flex-start; }

    &--align-center {
        display: flex;
        justify-content: center; } }
