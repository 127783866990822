#nav-toggler-wrapper {
  margin-top: 5px;
  .hamburger {
    padding: 15px 15px;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible; }

  &:hover {
 }    // opacity: 0.7

  &.is-active {
    &:hover {
 }      // opacity: 0.7

    .hamburger-inner {
      background-color: #fff;

      &::before, &::after {
        background-color: #fff; } } }

  .hamburger-box {
    width: 30px;
    height: 17px;
    display: inline-block;
    position: relative; }

  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
    width: 35px;
    height: 2px;
    background-color: #fff;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;

    &::before, &::after {
      height: 2px;
      background-color: #fff;
      border-radius: 4px;
      position: absolute;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease; }

    &::before, &::after {
      content: "";
      display: block; }

    &::before {
      top: -10px;
      width: 35px; }

    &::after {
      bottom: -10px;
      width: 25px; } }

  .hamburger-inner-end-button {
    display: block;
    top: 17px;
    margin-top: -2px;
    width: 15px;
    height: 2px;
    background-color: #fff;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    transition-delay: 0.22s; }

  .hamburger--spring {

    .hamburger-inner-end-button {
      transition: background-color 0s 0.13s linear; }

    .hamburger-inner {
      top: 2px;
      transition: background-color 0s 0.13s linear;


      &::before {
        top: 5px;
        transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

      &::after {
        top: 10px;
        transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); } }

    &.is-active .hamburger-inner {
      transition-delay: 0.22s;
      background-color: transparent !important;

      &::before {
        top: 0;
        transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translate3d(0, 10px, 0) rotate(45deg);
        width: 30px; }

      &::after {
        top: 0;
        transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translate3d(0, 10px, 0) rotate(-45deg);
        width: 30px; } }

    &.is-active .hamburger-inner-end-button {
      transition-delay: 0.22s;
      background-color: transparent !important; } } }
