$article-color-1: #0091A7;
$article-color-2: #0091A7;
$article-color-3: #000;

.block-articles {
  padding: 40px 170px;
  @media (max-width: #{map-get($grid-breakpoints, xl)}) {
      padding: 35px 40px; }

  a {
    display: inline-flex;
    align-items: center;
    font-size: 19px;
    color: $article-color-3;
    text-decoration: none;

    strong {
      flex: 1 0 auto;
      position: relative;
      display: inline-block;

      &:before {
        width: 0;
        bottom: -1px;
        height: 3px;
        content: "";
        position: absolute;
        background-color: $article-color-3;
        transition: 0.3s; }

      &:hover:before {
        width: 100%;
        transition: 0.3s; } }

    &:hover {
      text-decoration: none;

      .arrow-link {
        margin-left: 2.5*$base-width; } } }

  .article_row {
    display: flex;
    width: 100%;
    margin: 25px 0;
    @media (max-width: #{map-get($grid-breakpoints, xxl)}) {
      margin-bottom: 100px; }
    @media (max-width: #{map-get($grid-breakpoints, xl)}) {
      margin-bottom: 60px; }
    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
      flex-direction: column; }
    .article_img-container {
      width: 400px;
      height: 300px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding: 0 0 25px 0;
      @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        padding: 0;
        width: 100%;
        height: auto;
        margin-bottom: 35px; }
      picture {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover; } } }
    .article_text-container {
      padding: 0 50px;
      width: calc(100% - 400px);
      height: auto;
      @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        width: 100%;
        height: auto; }
      .articles-source {
        font-size: 14px;
        @media (max-width: #{map-get($grid-breakpoints, md)}) {
          font-size: 12px; } } } } }
