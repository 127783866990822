/*Text-Media Variales*/

$text-media-background-color-1: #0091A7;
$text-media-background-color-2: #357E81;
$text-media-background-color-3: #fff;
$text-media-color-1: #fff;
$text-media-color-2: #0091A7;
$text-media-color-3: #000;

.block-text-medien {
  border-top: 3px solid white;

  .text-medien-container {
    h2 {
      font-size: 60px;
      color: $text-media-color-1; }
    p,a {
      font-size: 18px;
      color: $text-media-color-1; }

    a {
      text-decoration: none;
      strong {
        position: relative;
        &::before {
          width: 0%;
          bottom: -2px;
          transition: 300ms;
          height: 3px;
          content: "";
          position: absolute;
          background-color: #fff; }
        &:hover::before {
          width: 100%; } }

      &:hover {
        text-decoration: none;
        .arrow-link {
          margin-left: 20px;
          transition: 0.5s; } } }

    .text-media-left {
      .media-container {
        padding: 0;
        img,picture {
          width: 100%;
          height: 100%;
          object-fit: cover; } }
      .text-container {
        padding: 115px 170px 115px 140px;
        background-color: $text-media-background-color-1;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        @media (max-width: #{map-get($grid-breakpoints, xl)}) {
          padding: 60px 40px;
          z-index: 3; } } }

    .text-media-right {
      flex-direction: row-reverse;
      .media-container {
        padding: 0;
        img,picture {
          width: 100%;
          height: 100%;
          object-fit: cover; } }
      .text-container {
        padding: 115px 140px 115px 170px;
        background-color: $text-media-background-color-1;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        @media (max-width: #{map-get($grid-breakpoints, xl)}) {
          padding: 60px 40px;
          z-index: 3; } } } }

  .forest-siluette-container {
    background-color: $text-media-background-color-2!important;
    border-right: 3px solid $text-media-color-1;
    display: flex;

    .forest-siluette {
      position: absolute;
      z-index: 3;
      margin-top: -44%;
      width: 100%;
      left: -5%;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%; } }

    .text-container {
      background-color: transparent!important; } }

  .text-media-left {
    .forest-siluette-container {
      .forest-siluette {
        right: -5%;
        left: unset;
        img {
          transform: rotateY(180deg); } } } } }
