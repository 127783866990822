// Base
$base-width: .8rem;

// Fonts
//$font-default: 'FilsonProBook', sans-serif
$font-default: 'filson-pro', sans-serif;

// Main Colors
$color-1: #0091A7;
$color-2: #357E81;
$color-3: #8EB2BA;
$color-4: #707070;
$color-5: #1D1D1B;
$color-6: #fff;

// Section Colors
$body-bg: white;
$body-color: #1D1D1B;
$header-bg: $color-1;
$footer-bg: $color-1;

// Text Colors
$headline1-color: $color-2;
$headline2-color: $color-2;
$link-color: $color-3;
$link-color-hover: $color-2;

// Misc Colors
$button-bg: $color-2;
$button-bg-hover: $color-6;
$button-color: $color-6;
$button-color-hover: $color-5;

$divider: #707070;



// Alternate
 :root {
    --kk-color-primary: #000; }
// https://erdmann-freunde.de/wissen/contao/frontend/einfuehrung-in-custom-properties/#nachteile

// Widths
$max: 2560px;
$boxed: 1520px;
$small: 1100px;

// Transition
$transition: all .3s ease-in-out;

// Breakpoints
$breakpoints: ("xs": 480px, "sm": 576px, "md": 768px, "lg": 992px, "xl": 1300px, "xxl": 1400px, "xxxl": 1680px);
$grid-breakpoints: ("xs": 0, "sm": 576px, "md": 768px, "lg": 992px, "xl": 1200px, "xxl": 1400px);
