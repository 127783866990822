$project-steps-color-1: #0091A7;
$project-steps-color-2: #fff;
$project-steps-color-3: rgba(255, 255, 255, 0.10);
$project-steps-bg-color-1: rgba(0,0,0,0.88);
$project-steps-bg-color-2: rgba(255, 255, 255, 0.77);
$project-steps-filter-1: invert(30%) sepia(65%) saturate(4845%) hue-rotate(170deg) brightness(103%) contrast(101%);

.block-project-steps {
  background-size: cover;
  background-repeat: no-repreat;
  background-position: 50% 50%;
  border-top: solid 3px white;

  .project-steps-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $project-steps-bg-color-1; }

  .project-steps-inner {
    padding: 126px 170px;
    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
      padding: 60px 40px; }

    .project-steps-text-container {
      padding-right: 100px;
      @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        padding: 0 50px; }
      h2 {
        color: $project-steps-color-1;
        margin-bottom: 30px; }
      p {
        color: $project-steps-color-2; } }

    .project-steps-icon-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        justify-content: center; }
      a {
        text-decoration: none;
        &:hover {
          text-decoration: none; } }
      .project-steps-icon-container-inner, {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: start;
        align-items: center;
        width: 185px;
        @media (max-width: #{map-get($grid-breakpoints, xl)}) {
          width: 150px; }
        @media (max-width: #{map-get($grid-breakpoints, lg)}) {
          justify-content: center;
          width: 165px; }
        @media (max-width: #{map-get($grid-breakpoints, lg)}) {
          width: 155px; }

        .project-steps_icons-number {
          margin-bottom: 10px;
          font-size: 40px;
          font-family: 'FilsonProBlack';
          color: $project-steps-color-3; }
        .project-steps_icons-icon {
          width: 77px;
          height: 77px;
          border: solid 2px $project-steps-color-2;
          border-radius: 50%;
          padding: 15px;
          transition: 0.5s;
          img {
            filter: $project-steps-filter-1; }
          &:hover {
            background-color: $project-steps-bg-color-2;
            transition: 0.5s; } }
        .project-steps_icons-text {
          height: 90px;
          display: flex;
          justify-content: center;
          align-items: center;
          p {
            font-size: 18px;
            color: $project-steps-color-2;
            text-align: center; } } } } } }
