.block-quote {
    padding: 40px 250px;
    text-align: center;
    @media (max-width: #{map-get($grid-breakpoints, xl)}) {
        padding: 20px 150px; }
    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        padding: 20px 100px; }
    @media (max-width: #{map-get($grid-breakpoints, md)}) {
        padding: 10px 60px; }
    @media (max-width: #{map-get($grid-breakpoints, sm)}) {
        padding: 10px 40px; }
    .quote-container {
        display: flex;
        flex-direction: row;
        @media (max-width: #{map-get($grid-breakpoints, xl)}) {
            flex-direction: column;
            align-items: center; }
        .quote-symbol {
            width: 250px;
            height: auto;
            padding: 5px;
            display: flex;
            align-items: center;
            @media (max-width: #{map-get($grid-breakpoints, xl)}) {
                width: 70px;
                height: auto;
                margin: 20px 0; }
            @media (max-width: #{map-get($grid-breakpoints, lg)}) {
                width: 50px;
                margin: 15px 0; }
            img {
                width: 100px;
                height: auto;
                object-fit: contain; } }

        .quote-symbol-container {
            display: flex;
            flex-direction: column;
            padding: 15px;
            .quote {
                font-size: 30px;
                font-family: 'FilsonProLight';
                line-height: 150%;
                font-weight: 900;
                @media (max-width: #{map-get($grid-breakpoints, lg)}) {
                    font-size: 20px; }
                @media (max-width: #{map-get($grid-breakpoints, md)}) {
                    font-size: 18px; } }
            p {
                font-size: 20px;
                font-family: 'FilsonProLight';
                line-height: 150%;
                @media (max-width: #{map-get($grid-breakpoints, md)}) {
                    font-size: 18px; } }

            .quote-source {
                font-size: 16px;
                font-weight: 900;
                @media (max-width: #{map-get($grid-breakpoints, md)}) {
                    font-size: 14px; } } } } }
