
.navbar {
  justify-content: flex-end;
  padding: 0;
  margin: 0; }

.main-menu-class {
  height: 570px;
  width: 455px;
  background-color: $color-2;
  font-size: 25px;
  padding: 80px 75px 120px 75px;
  list-style-type: none;
  position: absolute;
  right: -100%;
  opacity: 0;
  transition: 0.5s;
  top: 0;
  border-bottom-left-radius: 25%;
  @media (max-width: #{map-get($grid-breakpoints, lg)}) {
    height: 65vh;
    width: 355px;
    font-size: 18px;
    padding: 80px 50px; }
  @media (max-width: #{map-get($grid-breakpoints, md)}) {
    height: 100vh;
    border-bottom-left-radius: 25px; }

  @media (max-width: #{map-get($grid-breakpoints, sm)}) {
    padding-top: 110px; }
  &::after {
    content: '';
    background-image: url(/wp-content/uploads/saw-green.svg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 600px;
    height: 660px;
    position: absolute;
    top: 0;
    right: -8%;
    z-index: -3;
    transform: rotate(-111deg);
    transition: 0.5s;
    @media (max-width: #{map-get($grid-breakpoints, xl)}) {
      width: 400px;
      height: 460px;
      position: absolute;
      top: 150px;
      right: 30%; }
    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
      right: 5%;
      top: 185px; }
    @media (max-width: #{map-get($grid-breakpoints, md)}) {
      display: none; } }


  li {
    margin-bottom: 20px;




    a {
      color: white;
      text-decoration: none;
      width: fit-content;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        background-color: #fff;
        width: 0%;
        transition: 0.5s;
        height: 3px;
        bottom: -6px; }
      &:hover::before {
        width: 115%;
        transition: 0.5s; } } }




  li.children.active,
  li.active {
    a {
      &::before {
        width: 115%; } }
    ul.level-1 {
      li {
        a {
          &::before {
            width: 0%; } } } } }

  ul.level-1 {
    li.active {
      a {
        &::before {
          width: 115%!important; } } } }


  .children {
    button {
      display: none; }
    .level-1 {
      margin-top: 10px;
      margin-left: 15px;
      list-style-type: none;
      li {
        margin-bottom: 5px;
        a {
          color: $color-3;
          font-size: 20px;
          @media (max-width: #{map-get($grid-breakpoints, md)}) {
            font-size: 16px; } } } } } }

.main-menu-show {
  right: 0%;
  opacity: 1;
  transition: 0.5s;
  &::after {
    transform: rotate(-20deg);
    transition: 2.5s; }
  @media (max-width: #{map-get($grid-breakpoints, xl)}) {
    right: -7%; }
  @media (max-width: #{map-get($grid-breakpoints, md)}) {
    right: -10%; } }

.navbar-toggler {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 70px;
  border-radius: 0;
  padding: 20px 30px;
  top: 0;
  background-color: $color-2;
  transition: 0.5s;
  z-index: 1001;
  border-bottom-left-radius: 20px;
  &:hover {
    background-color: $color-1;
    transition: 0.5s; }

  #nav-text-menu {
    color: white;
    font-size: 20px;
    margin-left: 10px; }
  .icon-bar {
    display: block;
    height: 2px;
    margin: 4px 0;
    background-color: #fff;
    transition: all 0.4s;
    &.top-bar {
      width: 35px;
      opacity: 1;
      transition: 0.4s; }
    &.middle-1-bar {
      width: 35px;
      opacity: 1;
      transition: 0.4s; }
    &.middle-2-bar {
      width: 25px;
      opacity: 1;
      transition: 0.4s; }
    &.bottom-bar {
      width: 15px;
      opacity: 1;
      transition: 0.4s; } }

  @keyframes top-bar {
    0% {
      top: 2px;
      width: 35px;
      transform: rotate(0deg); }
    100% {
      width: 35px;
      top: 10px;
      transform: rotate(-40deg); } }
  @keyframes middle-1-bar {
    0% {
      top: 18px;
      width: 35px; }
    100% {
      width: 0px;
      top: 10px; } }
  @keyframes middle-2-bar {
    0% {
      top: 18px;
      width: 25px; }
    100% {
      width: 0px;
      top: 10px; } }
  @keyframes bottom-bar {
    0% {
      top: 18px;
      width: 15px;
      transform: rotate(0deg); }
    100% {
      width: 15px;
      top: 10px;
      transform: rotate(40deg); } }
  &.collapsed {
    span.icon-bar {
      width: 30px !important;
      margin: 0.85px 0;
      &.top-bar {
        -webkit-animation: top-bar .4s linear forwards;
        -o-animation: top-bar .4s linear forwards;
        animation: top-bar .4s linear forwards;
        margin-bottom: -2px;
        width: 30px; }
      &.middle-1-bar {
        animation: middle-bar .4s;
        opacity: 0;
        transition: 0.4s;
        display: none; }
      &.middle-2-bar {
        animation: middle-bar .4s;
        opacity: 0;
        transition: 0.4s;
        display: none; }
      &.bottom-bar {
        -webkit-animation: bottom-bar .4s linear forwards;
        -o-animation: bottom-bar .4s linear forwards;
        animation: bottom-bar .4s linear forwards;
        width: 30px;
        margin-left: 0; } } } }

.white-navigation {

  .navbar-toggler,
  #main-menu-id,
  #nav-text-menu, {
    background-color: #fff; }

  .icon-bar, {
    background-color: $color-2; }

  #nav-text-menu,
  #main-menu-id li a {
    color: $color-2; }

  // #main-menu-id
  //   &::after
  //     filter: invert(99%) sepia(1%) brightness(115%) contrast(1211%)

  #main-menu-id li a {
    &::before {
      background-color: $color-2; } }

  .hamburger-inner,
  .hamburger-inner-end-button {
    background-color: $color-2!important; }

  .hamburger-inner {
    &::after {
      background-color: $color-2!important; }
    &::before {
      background-color: $color-2!important; } }

  .logo-container {
    background-color: #fff;
    img {
      filter: invert(45%) sepia(22%) saturate(948%) hue-rotate(134deg) brightness(91%) contrast(91%); } }

  .main-menu-class {
    &::after {
      background-image: url(/wp-content/uploads/saw-white.svg); } } }
