.block-gallery-sort {
    padding: 25px 200px;
    @media (max-width: #{map-get($grid-breakpoints, xl)}) {
        padding: 25px 100px; }
    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        padding: 25px 50px; }
    .gallery-images-buttons-row {
        padding: 15px;
        margin: 0;
        justify-content: center;
        .gallery-filter-button-container {
            margin-bottom: 25px;
            .gallery-filter-button {
                background-color: $button-bg;
                color: $button-color;
                transition: $transition;
                padding: 15px 5px;
                // margin: 10px 15px 10px 0px
                border: solid 2px $button-bg;
                border-radius: 15px;

                p {
                    margin: 0;
                    padding: 0;
                    text-align: center;
                    @media (max-width: 1500px) {
                        font-size: 15px; } }
                &:hover {
                    background-color: $button-bg-hover;
                    color: $button-color-hover;
                    transition: $transition;
                    border: solid 2px $button-bg;
                    cursor: pointer; } }
            .gallery-filter-button-active {
                background-color: $button-bg-hover;
                color: $button-color-hover;
                transition: $transition;
                border: solid 2px $button-bg; } } }
    .gallery-images-row {
        padding: 0;
        margin: 0; }
    .gallery-image-container {
        margin: 0;
        padding: 15px;
        height: 500px;
        @media (max-width: #{map-get($grid-breakpoints, lg)}) {
            height: 350px; }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover; } } }

.fancybox-caption {
    font-size: 25px;
    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        font-size: 20px; }
    @media (max-width: #{map-get($grid-breakpoints, md)}) {
        font-size: 16px; } }
