
$button-primary-bg: #357E81;
$button-primary-bg-hover: #fff;
$button-primary-color: #fff;
$button-primary-color-hover: #357E81;

$button-secondary-bg: #fff;
$button-secondary-bg-hover: #357E81;
$button-secondary-color: #357E81;
$button-secondary-color-hover: #fff;




.button {
  border-radius: 25px;
  height: 50px;
  padding: 10px 40px;
  font-size: 19px;
  text-decoration: none;
  border: none;
  transition: $transition; }

.button-primary {
  background-color: $button-primary-bg;
  border: 2px solid $button-primary-bg;
  color: $button-primary-color;
  transition: $transition;
  &:hover {
    background-color: $button-primary-bg-hover;
    border: 2px solid $button-primary-bg;
    color: $button-primary-color-hover;
    transition: $transition; } }


.button-secondary {
  background-color: $button-secondary-bg;
  border: 2px solid $button-secondary-color;
  color: $button-secondary-color;
  transition: $transition;
  &:hover {
    background-color: $button-secondary-bg-hover;
    border: 2px solid $button-secondary-color;
    color: $button-secondary-color-hover;
    transition: $transition; } }
