/*Banner Variales*/

$banner-bg-color-1: #E8F0F1;
$banner-color-1: #0091A7;
$banner-color-2: #000;

.block-banner {
  border-top: 3px solid white;
  background-color: $banner-bg-color-1;

  .banner-container {
    padding: 130px 170px;

    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
      padding: 60px 40px; }

    h2 {
      color: $banner-color-1; }

    p {
      color: $banner-color-2;
      @media (max-width: #{map-get($grid-breakpoints, xl)}) {
        margin-bottom: 60px; } }

    a {
      color: $banner-color-2;
      font-size: 18px;
      text-decoration: none;
      &:hover {
        text-decoration: underline; } } }

  .background-image {
    position: absolute;
    bottom: -55%;
    right: -20px;
    max-width: 1114px;
    @media only screen and (max-width: 1550px) {
      bottom: -40%;
      right: -20px;
      max-width: 992px; }
    @media (max-width: #{map-get($grid-breakpoints, xl)}) {
      bottom: -60%; }
    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
      display: none; } }

  .banner-contact-container {
    display: flex;
    align-items: start;
    margin-top: 60px;
    flex-direction: column;
    .banner-contact-icon-container {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      justify-content: center;
      align-items: center; }
    .banner-icon {
      img {
        width: 23px;
        height: auto;
        object-fit: cover;
        margin-right: 11px;
        filter: invert(200%) sepia(96%) saturate(14%) hue-rotate(273deg) brightness(99%) contrast(104%); } } } }
